/* eslint-disable react/no-unknown-property */
import React from 'react';
import { Link, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import SVG from 'react-svg';
import Img from 'gatsby-image';
import Layout from '../components/layout';
import styles from './blog-list-template.module.scss';
import Head from 'components/head';

export default class BlogList extends React.Component {
  render() {
    const { currentPage, numPages } = this.props.pageContext;
    const isFirst = currentPage === 1;
    const isLast = currentPage === numPages;
    const prevPage =
      currentPage - 1 === 1 ? '/blog/' : (currentPage - 1).toString();
    const nextPage = '/blog/' + (currentPage + 1).toString();
    const posts = this.props.data.postings;
    const storyoftheweek = this.props.data.storyoftheweek;
    const heroImage = this.props.data.heroImage;
    const pageTitle = 'Blog';
    const isWhite = true;
    return (
      <div>
        <Head pageTitle="Blog" />
        <Layout isWhite={isWhite}>
          <Helmet
            meta={[
              {
                name: 'description',
                content:
                  'Hello and welcome to my blog. You\'ll find lots of intersting things here. Anywhere from UX, UI, opinions about technology and culture etc. I hope you\'ll like it!',
              },
              {
                name: 'keywords',
                content:
                  'blog, ui / ux, ui, ux, design, writing, code, opinions, pop, culture, technology, stuff',
              },
            ]}
            
          >
            <script type="application/ld+json">
    {`
        {
          "@context": "https://schema.org",
          "@type": "Blog",
          "url": "https://www.besler.pl",
          "name": "Besler Multplanetary Industries"
          }
        }
      `}
  </script>
            </Helmet>
          
                {storyoftheweek.edges.map(({ node }, index) => (
                  <Link
                to={node.fields.slug}
                className={[styles.row, styles.storyoftheweek].join(' ')}
                key={index}
              >
                
            <div className={[styles.column05,styles.headerweeksstory, styles.columnnopad].join(' ')}>
              <SVG
                className={styles.weeksstory}
                src={'/icons/StoryofTheWeek.svg'}
              />
            </div>
                <article className={styles.column4}>
                <header className={styles.meta}>
                    <span className={styles.category}>
                      {node.frontmatter.category}
                    </span>
                    <h2>{node.frontmatter.title}</h2>
                  </header>
                  <p>{node.frontmatter.description}</p>
                </article>
                <div
                  className={[
                    styles.column5,
                    styles.columnnopad,
                    styles.storyoftheweekImgWrapper,
                  ].join(' ')}
                >
                  <Img
                    alt={node.frontmatter.title}
                    className={styles.imageStoryOfTheWeek}
                    fluid={
                      node.frontmatter.image
                        ? node.frontmatter.image.childImageSharp.fluid
                        : {}
                    }
                  />
                  <SVG className={styles.xmark} src={'/icons/xmark.svg'} />
                  
              </div>
              </Link>
                  ))}
          {isFirst && (
          <section className={styles.row}>
            <div
              className={[
                styles.column12,
                styles.centerMargin,
                styles.doubleTriangle,
              ].join(' ')}
            >
              <SVG
                className={styles.bmiinter}
                src={'/icons/DoubleTriangle.svg'}
              />
            </div>
          </section>
          )}
          {isFirst && (
          <section className={styles.row}>
            <div
              className={[
                styles.column6,
                styles.columnnopad,
                styles.helloImage,
              ].join(' ')}
            >
              <Img
                fluid={heroImage ? heroImage.childImageSharp.fluid : {}}
                alt={pageTitle}
                className={styles.blogEnterance}
              />
            </div>
            <div
              className={[
                styles.column,
                styles.blogCom,
                styles.centerMargin,
              ].join(' ')}
            >
              <h2>Blog / Reviews / Dad Stories</h2>
              <p>Hello and welcome to my blog. You&apos;ll find lots of intersting things here. Anywhere from UX, UI, through opinions about technology and culture to some dad stories. I hope you&apos;ll like it! If you don&apos;t though - that&apos;s cool too. :)</p>
            </div>
          </section>
          )}
           {!isFirst && (
          <section className={styles.row}>
            <div className={[styles.centerMargin,
            styles.archivepage
              ].join(' ')}>
              <h1>Blog / Reviews / Dad Stories</h1>
              <h3>Continued...</h3>
              <SVG
                className={styles.bmiinter}
                src={'/icons/DoubleTriangle.svg'}
              />
            </div>
            </section>
            )}
          <div className={styles.blogPostList}>
            <div className={styles.blogPosts}>
              {posts.edges.map(({ node }, index) => (
                <Link
                  to={node.fields.slug}
                  className={styles.readMore}
                  key={index}
                >
                  <article itemscope itemtype="http://schema.org/Article" className={styles.postPreview}>
                    <div className={styles.postImage}>
                      <Img
                        className={styles.postImageInner}
                        fluid={
                          node.frontmatter.image
                            ? node.frontmatter.image.childImageSharp.fluid
                            : {}
                        }
                        alt={node.frontmatter.title}
                      />
                      <SVG
                        className={styles.postImageBG}
                        src={
                          '/icons/blog-bg-' +
                          (Math.floor(
                            Math.random() * (Math.floor(6) - Math.ceil(1))
                          ) +
                            Math.ceil(1)) +
                          '.svg'
                        }
                      />
                      <div
                        className={[styles.frame, styles.frameStyle].join(' ')}
                      />
                    </div>
                    <section className={styles.preview}>
                      <header className={styles.postHeader}>
                        <div className={styles.meta}>
                          <span className={styles.category}>
                            {node.frontmatter.category}
                          </span>
                          <span className={styles.dateauthor}>
                            {node.frontmatter.date},&nbsp;
                            {node.frontmatter.author}
                          </span>
                        </div>
                        <h3 itemprop="name">{node.frontmatter.title}</h3>
                      </header>
                      <p className={styles.copy}>{node.frontmatter.description}</p>
                      <SVG
                        className={styles.arrow}
                        src={'/icons/arrow_right.svg'}
                      />
                    </section>
                  </article>
                </Link>
              ))}
            </div>
            <section className={styles.row}>
            <div className={[styles.centerMargin,
            styles.pagination
              ].join(' ')}>
              {!isFirst && (
                <Link to={prevPage} rel="prev">
                  ← Previous Page
                </Link>
              )}
              {Array.from({ length: numPages }, (_, i) => (
                <Link
                  key={`pagination-number${i + 1}`}
                  to={`/blog/${i === 0 ? '' : i + 1}`}
                >
                  {i + 1}
                </Link>
              ))}
              {!isLast && (
                <Link to={nextPage} rel="next">
                  Next Page →
                </Link>
              )}
            </div>
            </section>
          </div>
        </Layout>
      </div>
    );
  }
}

BlogList.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
};
export const blogListQuery = graphql`
  query blogListQuery($skip: Int!, $limit: Int!) {
    postings: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: { draft: { ne: true } }
        fileAbsolutePath: { regex: "/(blog)/" }
      }
      limit: $limit
      skip: $skip
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            image {
              childImageSharp {
                fluid(maxHeight: 750, quality: 85) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            date(formatString: "DD.MM.YY")
            title
            category
            author
            description
          }
          fields {
            slug
          }
        }
      }
    }
    heroImage: file(
      relativePath: {
        eq: "images/luiz-felipe-silva-carmo-aTTtWFJB7z4-unsplash.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxHeight: 400, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    storyoftheweek: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: { storyoftheweek: { ne: false }, draft: { ne: true } }
        fileAbsolutePath: { regex: "/(blog)/" }
      }
      limit: 1
      skip: $skip
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            image {
              childImageSharp {
                fluid(maxHeight: 750, quality: 85) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            date(formatString: "DD.MM.YY")
            title
            category
            storyoftheweek
            author
            description
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;